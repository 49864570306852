import features from "./features.png";
import partner from "./partner.png";
import join from "./join.png";
import bigtool from "./bigtool.png";

export const IMAGES = {
  features,
  partner,
  join,
  bigtool,
  // emptyNotifications,
};
