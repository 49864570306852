import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { addToWaitingList } from "../services/queryPostWaitingList";
import { execute } from "../services";

export function useAddToWaitingList() {
  const toast = useToast({ position: "top" });
  const { isError, error, data, mutate, isLoading, isPending } = useMutation({
    // mutationKey: "addToWaitingList",
    mutationFn: async (params) => {
      return execute(addToWaitingList(params));
    },

    onError: (error) => {
      toast({
        title: error?.response?.data?.message || "Something Went Wrong",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    },
    onSuccess: (data) => {
      toast({
        title: "Added to waiting list successfully ",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
    },
  });

  return { isError, error, data, mutate, isLoading, isPending };
}
