import { UnorderedList, ListItem } from "@chakra-ui/react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineWhatsApp,
  AiFillYoutube,
  AiFillTikTok,
} from "react-icons/ai";

const SocialLinks = () => {
  return (
    <UnorderedList
      display="flex"
      // w={"30%"}
      alignItems="center"
      gap={3}
      listStyleType="none"
      ml={0}
    >
      <ListItem>
        <a
          href="https://www.facebook.com/MedicStaff.us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillFacebook style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
      <ListItem>
        <a
          href="https://www.instagram.com/medicstaff.us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillInstagram style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
      <ListItem>
        <a
          href="https://linkedin.com/company/medicstaff-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillLinkedin style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
      <ListItem>
        <a
          href="https://api.whatsapp.com/send?phone=15712100022&text=Thanks%20for%20contacting%20Medic%20Staff.%0A%0AWe%20Will%20be%20with%20you%20Shortly."
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineWhatsApp style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
      <ListItem>
        <a
          href="https://www.youtube.com/@Medicstaff-US"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillYoutube style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
      <ListItem>
        <a
          href="https://tiktok.com/@medicstaff"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillTikTok style={{ width: "24px", height: "24px" }} />
        </a>
      </ListItem>
    </UnorderedList>
  );
};

export default SocialLinks;
