import React from "react";
import { Box, Flex, Text, Icon, SimpleGrid } from "@chakra-ui/react";
import { FaHandsHelping } from "react-icons/fa"; // Example icon, replace with the actual icon you're using
import { AgenciesIcon } from "../atoms/icons/agencies-icon";

const AgenciesBenefits: React.FC = () => {
  return (
    <Box mx={[5, 15]} maxW="1024px">
      <Flex
        direction={["column", "column", "row"]}
        // align="center"
        justify="space-between"
      >
        <Box flex="1" textAlign={["left", "left"]} gap={5} mb={[10, 10, 0]}>
          <AgenciesIcon mb={2} />

          <Text fontSize="2xl" textAlign="left" fontWeight="bold">
            How Agencies Benefit
          </Text>
          <Text fontSize="lg" mb={4}>
            Agencies partnering with{" "}
            <Text as="span" fontWeight="bold">
              Medicstaff
            </Text>{" "}
            experience a seamless hiring process that enhances efficiency and
            effectiveness.
          </Text>
          <Text fontSize="lg" mb={4}>
            Our platform allows you to effortlessly shortlist qualified nurses,
            manage your team of recruiters and advisors, and oversee your
            subscription plan and agency profile all in one place.
          </Text>
          <Text fontSize="lg" mb={4}>
            Simplify your recruitment workflow and focus on finding the best
            talent to meet your staffing needs.
          </Text>
        </Box>
        <Box flex="1" pl={[0, 0, 10]}>
          <SimpleGrid columns={1} spacing={6}>
            <Box
              bg="#FDEDEF"
              borderWidth={1}
              borderColor={"#F597A0"}
              p={6}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#912E38">
                Streamlined Recruitment
              </Text>
              <Text mt={2} color={"#912E38"}>
                Access a pre-vetted pool of qualified candidates.
              </Text>
            </Box>
            <Box
              bg="#FDEDEF"
              borderWidth={1}
              borderColor={"#F597A0"}
              p={6}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#912E38">
                Global Reach
              </Text>
              <Text mt={2} color={"#912E38"}>
                Connect with nurses from various countries, expanding your
                talent pool.{" "}
              </Text>
            </Box>
            <Box
              bg="#FDEDEF"
              borderWidth={1}
              borderColor={"#F597A0"}
              p={6}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#912E38">
                Efficient Matching
              </Text>
              <Text mt={2} color={"#912E38"}>
                Utilize our platform's matching tools to find the best fit for
                your needs.{" "}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </Box>
  );
};

export default AgenciesBenefits;
