import React from "react";
import {
  Box,
  Heading,
  VStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const FooterLinks: React.FC = () => {
  return (
    <Box
      // alignSelf={"flex-end"}
      display="flex"
      w={["90%", "auto"]}
      flex={1}
      gap={12}
      flexDir={["column", "row"]}
      justifyContent="space-between"
      me={["0%", "13%"]}
    >
      <VStack align={["start"]}>
        <Heading
          as="h3"
          // fontSize="sm"
          // fontWeight="bold"
          color="#EE4B5C"
          letterSpacing="wider"
          textTransform="uppercase"
          fontSize={14}
          fontWeight={600}
        >
          Main
        </Heading>
        <UnorderedList styleType="none" m={0} spacing={3}>
          <ListItem fontSize={16} fontWeight={400}>
            Home
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Specialities
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Testimonials
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Refer a Friend
          </ListItem>
        </UnorderedList>
      </VStack>
      <VStack align={["start"]}>
        <Heading
          as="h3"
          fontSize={14}
          fontWeight={600}
          color="#EE4B5C"
          letterSpacing="wider"
          textTransform="uppercase"
        >
          Forum
        </Heading>
        <UnorderedList styleType="none" m={0} spacing={3}>
          <ListItem fontSize={16} fontWeight={400}>
            E-Learning
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Sponsorship & Immigration
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Ask a Lawyer
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Live & Work in USA
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            States Licensure
          </ListItem>
        </UnorderedList>
      </VStack>
      <VStack align={["start"]}>
        <Heading
          as="h3"
          fontSize={14}
          fontWeight={600}
          color="#EE4B5C"
          letterSpacing="wider"
          textTransform="uppercase"
        >
          Company
        </Heading>
        <UnorderedList styleType="none" m={0} spacing={3}>
          <ListItem fontSize={16} fontWeight={400}>
            About
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Contact
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            FAQ
          </ListItem>
          <ListItem fontSize={16} fontWeight={400}>
            Legal
          </ListItem>
        </UnorderedList>
      </VStack>
    </Box>
  );
};

export default FooterLinks;
