// import qs from "qs";

import { BASE_URL } from "./utils";

export const addToWaitingList = (params) => {
  // console.log("addToWaitingList", name, email);

  const { name, email } = params;
  return {
    url: `${BASE_URL}/waitlists`,
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
    },

    body: { name, email },
    // paramsSerializer: (params) => {
    //   return qs.stringify(params);
    // },
  };
};
