import { Icon } from "@chakra-ui/react";

export const Email = (props) => {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8342 17.8262C17.6752 17.8262 19.1675 16.3338 19.1675 14.4928V7.01174C19.1678 6.999 19.1678 6.98622 19.1675 6.97342V6.15951C19.1675 4.31856 17.6752 2.82617 15.8342 2.82617H4.16754C2.32659 2.82617 0.834206 4.31856 0.834206 6.15951V6.97343C0.83391 6.98622 0.833911 6.999 0.834206 7.01174V14.4928C0.834206 16.3338 2.32659 17.8262 4.16754 17.8262H15.8342ZM2.50087 14.4928C2.50087 15.4133 3.24706 16.1595 4.16754 16.1595H15.8342C16.7547 16.1595 17.5009 15.4133 17.5009 14.4928V8.2237L11.2388 10.7285C10.4441 11.0464 9.55761 11.0464 8.7629 10.7285L2.50087 8.2237V14.4928ZM10.6199 9.18105L17.5009 6.42864V6.15951C17.5009 5.23903 16.7547 4.49284 15.8342 4.49284H4.16754C3.24706 4.49284 2.50087 5.23903 2.50087 6.15951V6.42864L9.38189 9.18105C9.77924 9.33999 10.2225 9.33999 10.6199 9.18105Z"
        fill="#74829C"
      />
    </Icon>
  );
};
