import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { UserIcon } from "../atoms/icons/user-icon";
import { BaseFormInput } from "../atoms/BaseInput/BaseInput";
import { Email } from "../atoms/icons/email-icon";
import { Logo } from "../atoms/icons/logo";
import { IMAGES } from "../../assets";
import { HeroIcon } from "../atoms/icons/hero-icon";
import { useForm } from "react-hook-form";
import { useAddToWaitingList } from "../../hooks/useAddToWaitingList";

const Hero: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate: addToList, isLoading, isPending } = useAddToWaitingList();

  const onSubmit = async (data) => {
    const { name, email }: { name: string; email: string } = data;
    console.log("data", name, email);
    addToList(data);
    reset();
  };

  return (
    <Stack
      // maxH={"300px"}
      mb={[10, 20]}
      pe={[0, 5]}
      justifyContent={"space-between"}
      flexDir={["column", "row"]}
      bg="linear-gradient(181.02deg, #B32F47 0.34%, #FD4A4F 62.91%)"
    >
      <Stack
        // mb={5}
        // m={10}
        // pt={4}
        mx={[3, 20]}
        mt={[3, 8]}
        w={["auto", "600px"]}
        h={["auto", "570px"]}
        // mx={[5, 10]}
        // my={[5, 10]}
        // mb={[5, 9]}
        // flex={0.7}
        // w={"50%"}
        alignSelf={"flex-start"}
        flexDir={["column"]}
        alignContent={"flex-start"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        <Logo />
        <Box
          alignSelf={"center"}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          // flex={1}
          // w={"98%"}
          textAlign={["left", "left", "left"]}
        >
          <Text fontSize="48" fontWeight="700" color={"white"}>
            Empowering Nurses Worldwide
          </Text>
          <Text fontSize="16" fontWeight="500" color={"white"} my={4}>
            Connecting you to job opportunities with support in the USA
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              gap={3}
              alignContent={"flex-start"}
              alignItems={"flex-start"}
              w={["full", "60%"]}
              justifyContent={"flex-start"}
            >
              <InputGroup
                backgroundColor={"white"}
                alignItems={"center"}
                borderRadius={"8px"}
              >
                <InputLeftElement pointerEvents="none">
                  <UserIcon size={20} color="#74829C" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Full name"
                  _placeholder={{
                    opacity: 1,
                    color: "#98A2B3",
                    fontWeight: "400",
                    fontSize: 12,
                    fontFamily: "Poppins",
                  }}
                  {...register("name", { required: "Name is required" })}
                />
              </InputGroup>
              <InputGroup
                backgroundColor={"white"}
                alignItems={"center"}
                borderRadius={"8px"}
              >
                <InputLeftElement pointerEvents="none">
                  <Email size={20} color="#74829C" />
                </InputLeftElement>
                <Input
                  {...register("email", { required: "Email is required" })}
                  type="text"
                  placeholder="Email address"
                  _placeholder={{
                    opacity: 1,
                    color: "#98A2B3",
                    fontWeight: "400",
                    fontSize: 12,
                    fontFamily: "Poppins",
                  }}
                />
              </InputGroup>
              <Button
                borderRadius={8}
                size="md"
                color={"white"}
                bgColor={"#233862"}
                variant="primary"
                fontSize="sm"
                type="submit"
                isLoading={isLoading || isPending}

                //   minW="140px"
                //   onClick={onClick}
              >
                Join the waitlist
              </Button>
            </VStack>
          </form>
        </Box>
      </Stack>
      {/* <Box> */}

      <HeroIcon alignSelf={"flex-end"} h={["20%", "50%"]} w={["100%", "50%"]} />
      {/* <HeroIcon h={["20%", "560px"]} w={["100%", "728.5px"]} /> */}
      {/* </Box> */}
      {/* </Flex> */}
    </Stack>
  );
};

export default Hero;
