import { Icon } from "@chakra-ui/react";

export function ToolIcon(props) {
  return (
    <Icon
      width="48px"
      height="61px"
      viewBox="0 0 48 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9.35205C18 13.2075 15.5757 16.4967 12.1684 17.7785C12.5857 20.7921 13.7608 23.6581 15.4931 25.8852C17.6607 28.6722 20.5927 30.3521 24 30.3521C27.4073 30.3521 30.3393 28.6722 32.5069 25.8852C34.2392 23.6581 35.4143 20.7921 35.8316 17.7785C32.4243 16.4967 30 13.2075 30 9.35205C30 4.38149 34.0294 0.352051 39 0.352051C43.9706 0.352051 48 4.38149 48 9.35205C48 13.3218 45.4298 16.6913 41.8628 17.8872C41.4022 22.1496 39.7958 26.2868 37.2431 29.5689C34.7318 32.7977 31.2343 35.2679 27 36.0723V42.8642C30.4956 44.0997 33 47.4334 33 51.3521C33 56.3226 28.9706 60.3521 24 60.3521C19.0294 60.3521 15 56.3226 15 51.3521C15 47.4334 17.5044 44.0997 21 42.8642V36.0723C16.7657 35.2679 13.2682 32.7977 10.7569 29.5689C8.20418 26.2868 6.59777 22.1496 6.13724 17.8872C2.57016 16.6913 0 13.3218 0 9.35205C0 4.38149 4.02944 0.352051 9 0.352051C13.9706 0.352051 18 4.38149 18 9.35205ZM9 6.35205C10.6569 6.35205 12 7.6952 12 9.35205C12 11.0089 10.6569 12.3521 9 12.3521C7.34315 12.3521 6 11.0089 6 9.35205C6 7.6952 7.34315 6.35205 9 6.35205ZM39 12.3521C40.6569 12.3521 42 11.0089 42 9.35205C42 7.6952 40.6569 6.35205 39 6.35205C37.3431 6.35205 36 7.6952 36 9.35205C36 11.0089 37.3431 12.3521 39 12.3521ZM21 51.3521C21 49.6952 22.3431 48.3521 24 48.3521C25.6569 48.3521 27 49.6952 27 51.3521C27 53.0089 25.6569 54.3521 24 54.3521C22.3431 54.3521 21 53.0089 21 51.3521Z"
        fill="#1671D9"
      />
    </Icon>
  );
}
