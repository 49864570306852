import { ChakraProvider, Stack } from "@chakra-ui/react";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Features from "./components/Features/Features";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import PartnerSection from "./components/PartnerSection/PartnerSection";
import Footer from "./components/Footer/Footer";
import WhyChooseMedicstaff from "./components/WhyChooseMedicstaff/WhyChooseMedicstaff";
import AgenciesBenefits from "./components/AgenciesBenefits/AgenciesBenefits";
import JoinToday from "./components/JoinToday/JoinToday";
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import { Element } from "react-scroll";
import theme from "./assets/theme";

function App() {
  const queryClient = new QueryClient();

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {/* <Header /> */}
        <Element name="hero">
          <Hero />
        </Element>
        <Stack alignItems={"center"} gap={[10, 20]}>
          <Features />
          <WhyChooseMedicstaff />
          <HowItWorks />
          <PartnerSection />
          <AgenciesBenefits />
        </Stack>
        <Element name="join">
          <JoinToday />
        </Element>
        <Footer />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
