import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/400.css";

const theme = extendTheme({
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
  },
  styles: {
    global: {
      body: {
        fontFamily: "Poppins, sans-serif",
      },
    },
  },
});

export default theme;
