import React from "react";
import { Box, Flex, Text, Icon, SimpleGrid } from "@chakra-ui/react";
import { FaStethoscope } from "react-icons/fa";
import { ToolIcon } from "../atoms/icons/tool-icon";

const WhyChooseMedicstaff: React.FC = () => {
  return (
    <Box mx={[2, 15]} maxW="1024px">
      <Flex
        direction={["column", "column", "row"]}
        align="start"
        justify="space-between"
      >
        <Box mx={5} flex="1">
          <ToolIcon />

          <Flex align="center" mb={4}>
            <Text fontSize="2xl" fontWeight="bold">
              Why Choose{" "}
              <Text as="span" color="#1671D9">
                Medicstaff
              </Text>
              ?
            </Text>
          </Flex>
          <Text fontSize="lg" mt={4}>
            Nurses who join{" "}
            <Text as="span" fontWeight="bold">
              Medicstaff
            </Text>{" "}
            can look forward to unmatched career opportunities and growth.
          </Text>
          <Text fontSize="lg" mt={4}>
            Our platform connects you with top healthcare facilities in the USA,
            providing resources and guidance to help you succeed. Whether you're
            just starting or looking to advance your career,{" "}
            <Text as="span" fontWeight="bold">
              Medicstaff
            </Text>{" "}
            offers a supportive community and the tools you need to achieve your
            professional goals.
          </Text>
        </Box>
        <Box mx={5} flex="1" mt={[10, 10, 0]}>
          <SimpleGrid columns={1} spacing={6}>
            <Box
              borderColor={"#94BEEE"}
              bg="#E8F1FB"
              p={6}
              borderWidth={1}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#0C3E77">
                Global Opportunities
              </Text>
              <Text mt={2} color={"#0C3E77"}>
                Connect with top healthcare facilities in the USA.
              </Text>
            </Box>
            <Box
              borderColor={"#94BEEE"}
              bg="#E8F1FB"
              borderWidth={1}
              p={6}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#0C3E77">
                Professional Growth
              </Text>
              <Text color={"#0C3E77"} mt={2}>
                Access resources and guidance to advance your career.
              </Text>
            </Box>
            <Box
              borderColor={"#94BEEE"}
              bg="#E8F1FB"
              borderWidth={1}
              p={6}
              borderRadius="md"
              shadow="md"
            >
              <Text fontSize="xl" fontWeight="bold" color="#0C3E77">
                Support Network
              </Text>
              <Text mt={2} color={"#0C3E77"}>
                Join a community of professionals to share experiences and
                advice.
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </Box>
  );
};

export default WhyChooseMedicstaff;
