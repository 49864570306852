import { Icon } from "@chakra-ui/react";

export function PhoneIcon(props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2.65495C1.5 1.73447 2.24619 0.988281 3.16667 0.988281H5.89937C6.25806 0.988281 6.57651 1.21781 6.68994 1.55809L7.93811 5.30262C8.06926 5.69605 7.89115 6.12604 7.52022 6.3115L5.63917 7.25203C6.55771 9.2893 8.19898 10.9306 10.2363 11.8491L11.1768 9.96806C11.3622 9.59713 11.7922 9.41902 12.1857 9.55017L15.9302 10.7983C16.2705 10.9118 16.5 11.2302 16.5 11.5889V14.3216C16.5 15.2421 15.7538 15.9883 14.8333 15.9883H14C7.09644 15.9883 1.5 10.3918 1.5 3.48828V2.65495Z"
        stroke="#192846"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
