// services/index.js
import axios from "axios";

export const execute = ({
  method,
  url,
  body,
  params,
  headers,
  responseType = "json",
  contentType = "application/json",
  paramsSerializer,
}) => {
  return axios
    .request({
      url,
      method,
      data: body,
      params,
      paramsSerializer,
      headers: {
        ...headers,
        ["content-type"]: contentType,
      },
      responseType,
    })
    .catch((error) => {
      throw error;
    });
};
