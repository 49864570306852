import { Icon } from '@chakra-ui/react';

export function UserIcon(props) {
  return (
    <Icon width="18px" height="18px" fill="red" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5A3.75 3.75 0 1 0 9 9a3.75 3.75 0 0 0 0-7.5ZM6.75 5.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM9 17.25c-1.155 0-2.828-.262-4.083-.812-.617-.27-1.244-.656-1.608-1.215A1.83 1.83 0 0 1 3 14.196c.004-.383.13-.75.342-1.091C4.369 11.449 6.47 9.75 9 9.75c2.53 0 4.631 1.7 5.658 3.355.212.341.338.708.342 1.091a1.83 1.83 0 0 1-.309 1.027c-.364.559-.991.945-1.608 1.215-1.255.55-2.928.812-4.083.812Zm-4.383-3.355c-.1.16-.116.262-.117.317 0 .05.013.111.066.192.124.191.427.43.952.66 1.028.45 2.487.686 3.482.686.995 0 2.454-.236 3.482-.686.525-.23.828-.469.953-.66a.335.335 0 0 0 .065-.192c0-.055-.018-.157-.117-.317-.841-1.356-2.52-2.645-4.383-2.645s-3.542 1.289-4.383 2.645Z"
        fill="currentColor"
      />
    </Icon>
  );
}
