import React from "react";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { IMAGES } from "../../assets";
import { Link as ScrollLink } from "react-scroll";

const PartnerSection: React.FC = () => {
  return (
    <Box mx={[5, 15]} maxW="1024px">
      <Flex
        direction={["column", "column", "row"]}
        align="center"
        justify="space-between"
      >
        <Box flex="1" textAlign={["center", "center", "left"]} mb={[10, 10, 0]}>
          <Image
            src={IMAGES.partner}
            w={["full", "100%"]}
            // h={["full", "60%"]}
          />
        </Box>
        <Box flex="1" pl={[0, 0, 10]}>
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            Why partner with{" "}
            <Text as="span" color="#EE4B5C">
              Medicstaff
            </Text>
          </Text>
          <Text fontSize="lg" mb={4}>
            Agencies partnering with{" "}
            <Text as="span" fontWeight="bold">
              Medicstaff
            </Text>{" "}
            gain access to a diverse pool of highly qualified nurses from around
            the world.
          </Text>
          <Text fontSize="lg" mb={4}>
            Our platform simplifies the recruitment process, allowing you to
            find the perfect candidates efficiently and effectively.
          </Text>
          <Text fontSize="lg" fontWeight="bold" mb={6}>
            Leverage our network to meet your staffing needs and ensure
            top-quality patient care.
          </Text>
          <ScrollLink to="join" smooth={true} duration={500}>
            <Button
              borderRadius={8}
              type="button"
              size="md"
              color={"white"}
              bgColor={"#233862"}
              variant="primary"
              fontSize="sm"
              //   minW="140px"
              //   onClick={onClick}
            >
              Join the waitlist
            </Button>
          </ScrollLink>
        </Box>
      </Flex>
    </Box>
  );
};

export default PartnerSection;
