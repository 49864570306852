import React from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Stack,
  InputGroup,
  InputLeftElement,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAddToWaitingList } from "../../hooks/useAddToWaitingList";
import { Email } from "../atoms/icons/email-icon";
import { UserIcon } from "../atoms/icons/user-icon";
import { IMAGES } from "../../assets";

const JoinToday: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate: addToList, isLoading, isPending } = useAddToWaitingList();

  const onSubmit = async (data) => {
    const { name, email }: { name: string; email: string } = data;
    console.log("data", name, email);
    addToList(data);
    reset();
  };

  return (
    <VStack
      mt={[10, 20]}
      bgImage={IMAGES.join}
      bgSize={["cover", "cover"]}
      bgPosition="center"
      // blockSize={"fit-content"}
      h={["360px", "full"]}
      bgRepeat="no-repeat"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Flex direction="column" align="center" justify="center" mx="auto">
        <Text fontSize={["28px", "48px"]} fontWeight="700" color="white" mb={4}>
          Join Medicstaff Today
        </Text>
        <Text maxW={"80%"} fontSize={["12px", "15px"]} color="white" mb={6}>
          Sign up now for early access and be the first to benefit from our
          comprehensive services and support
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Stack
            spacing={4}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            w="100%"
          >
            <InputGroup
              backgroundColor={"white"}
              alignItems={"center"}
              borderRadius={"8px"}
              w={["90%", "70%"]}
            >
              <InputLeftElement pointerEvents="none">
                <UserIcon size={20} color="#74829C" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Full name"
                {...register("name", { required: "Name is required" })}
                _placeholder={{
                  opacity: 1,
                  color: "#98A2B3",
                  fontWeight: "400",
                  fontSize: 12,
                  fontFamily: "Poppins",
                }}
              />
            </InputGroup>
            <InputGroup
              backgroundColor={"white"}
              alignItems={"center"}
              borderRadius={"8px"}
              w={["90%", "70%"]}
            >
              <InputLeftElement pointerEvents="none">
                <Email size={20} color="#74829C" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Email address"
                {...register("email", { required: "Email is required" })}
                _placeholder={{
                  opacity: 1,
                  color: "#98A2B3",
                  fontWeight: "400",
                  fontSize: 12,
                  fontFamily: "Poppins",
                }}
              />
            </InputGroup>
            <Button
              w={["60%", "30%"]}
              type="submit"
              size="md"
              borderRadius={8}
              color={"white"}
              bgColor={"#233862"}
              variant="primary"
              isLoading={isLoading || isPending}
            >
              Join the waitlist
            </Button>
          </Stack>
        </form>
      </Flex>
    </VStack>
  );
};

export default JoinToday;
