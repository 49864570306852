import { Icon } from "@chakra-ui/react";

export function AgenciesIcon(props) {
  return (
    <Icon
      width="67px"
      height="52px"
      viewBox="0 0 67 52"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.0007 0.81543C26.3732 0.81543 21.0007 6.18801 21.0007 12.8154C21.0007 19.4428 26.3732 24.8154 33.0007 24.8154C39.6281 24.8154 45.0007 19.4428 45.0007 12.8154C45.0007 6.18801 39.6281 0.81543 33.0007 0.81543ZM27.0007 12.8154C27.0007 9.50172 29.687 6.81543 33.0007 6.81543C36.3144 6.81543 39.0007 9.50172 39.0007 12.8154C39.0007 16.1291 36.3144 18.8154 33.0007 18.8154C29.687 18.8154 27.0007 16.1291 27.0007 12.8154Z"
        fill="#EE4B5C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.0007 51.8154C29.59 51.8154 24.6723 51.0093 20.9402 49.2942C19.1095 48.4529 17.1451 47.2051 15.9864 45.3381C15.3748 44.3526 14.9883 43.1908 15.001 41.8977C15.0135 40.617 15.4151 39.4024 16.0728 38.2893C19.0802 33.1996 25.3193 27.8154 33.0007 27.8154C40.682 27.8154 46.9211 33.1996 49.9285 38.2893C50.5862 39.4024 50.9878 40.617 51.0004 41.8977C51.013 43.1908 50.6265 44.3526 50.0149 45.3382C48.8562 47.2051 46.8919 48.4529 45.0611 49.2942C41.329 51.0093 36.4113 51.8154 33.0007 51.8154ZM21.2384 41.3416C21.0087 41.7304 21.001 41.9235 21.0007 41.9563C21.0004 41.978 20.9997 42.0376 21.0844 42.1742C21.3226 42.558 22.0144 43.1846 23.4456 43.8424C26.2375 45.1254 30.2672 45.8154 33.0007 45.8154C35.7341 45.8154 39.7639 45.1254 42.5557 43.8424C43.987 43.1846 44.6787 42.558 44.9169 42.1742C45.0016 42.0376 45.0009 41.9795 45.0007 41.9578C45.0003 41.925 44.9927 41.7304 44.7629 41.3416C42.4313 37.3956 37.8712 33.8154 33.0007 33.8154C28.1301 33.8154 23.5701 37.3956 21.2384 41.3416Z"
        fill="#EE4B5C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.50066 21.8154C4.50066 16.8449 8.5301 12.8154 13.5007 12.8154C18.4712 12.8154 22.5007 16.8449 22.5007 21.8154C22.5007 26.786 18.4712 30.8154 13.5007 30.8154C8.5301 30.8154 4.50066 26.786 4.50066 21.8154ZM13.5007 18.8154C11.8438 18.8154 10.5007 20.1586 10.5007 21.8154C10.5007 23.4723 11.8438 24.8154 13.5007 24.8154C15.1575 24.8154 16.5007 23.4723 16.5007 21.8154C16.5007 20.1586 15.1575 18.8154 13.5007 18.8154Z"
        fill="#EE4B5C"
      />
      <path
        d="M5.68786 44.1492C4.95124 45.6333 3.15098 46.2393 1.66689 45.5026C0.182787 44.766 -0.42316 42.9658 0.313465 41.4817C1.41562 39.2611 3.08384 37.0316 5.18241 35.3257C7.28179 33.6192 9.97197 32.3154 13.0422 32.3154C14.699 32.3154 16.0422 33.6586 16.0422 35.3154C16.0422 36.9723 14.699 38.3154 13.0422 38.3154C11.7192 38.3154 10.323 38.8793 8.96707 39.9815C7.61033 41.0844 6.45437 42.6049 5.68786 44.1492Z"
        fill="#EE4B5C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.4308 21.8154C44.4308 16.8449 48.4603 12.8154 53.4308 12.8154C58.4014 12.8154 62.4308 16.8449 62.4308 21.8154C62.4308 26.786 58.4014 30.8154 53.4308 30.8154C48.4603 30.8154 44.4308 26.786 44.4308 21.8154ZM53.4308 18.8154C51.774 18.8154 50.4308 20.1586 50.4308 21.8154C50.4308 23.4723 51.774 24.8154 53.4308 24.8154C55.0877 24.8154 56.4308 23.4723 56.4308 21.8154C56.4308 20.1586 55.0877 18.8154 53.4308 18.8154Z"
        fill="#EE4B5C"
      />
      <path
        d="M65.7011 41.4817C66.4377 42.9658 65.8317 44.766 64.3477 45.5026C62.8636 46.2393 61.0633 45.6333 60.3267 44.1492C59.5602 42.6049 58.4042 41.0844 57.0475 39.9815C55.6915 38.8793 54.2953 38.3154 52.9723 38.3154C51.3155 38.3154 49.9723 36.9723 49.9723 35.3154C49.9723 33.6586 51.3155 32.3154 52.9723 32.3154C56.0426 32.3154 58.7327 33.6191 60.8321 35.3257C62.9307 37.0316 64.5989 39.2611 65.7011 41.4817Z"
        fill="#EE4B5C"
      />
    </Icon>
  );
}
