import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Image,
  Stack,
  Button,
} from "@chakra-ui/react";
import { FeaturesIcon } from "../atoms/icons/features-icon";
import { IMAGES } from "../../assets";
import { Link as ScrollLink } from "react-scroll";

const Features: React.FC = () => {
  return (
    <Stack maxW="1024px" mx={[2, 15]} flexDir={["column", "row"]}>
      <Image src={IMAGES.features} w={["full", "50%"]} h={["full", "50%"]} />
      <Box py={10} px={6} maxW="800px" mx="auto">
        <Text fontSize="3xl" fontWeight="bold">
          What is{" "}
          <Text as="span" color="blue.500">
            Medicstaff
          </Text>
        </Text>
        <Text fontSize="lg" fontWeight="bold" mt={4}>
          Medicstaff{" "}
          <Text as="span" fontWeight="normal">
            is dedicated to bridging the gap between skilled nurses worldwide
            and leading healthcare facilities in the USA.
          </Text>
        </Text>
        <Text fontSize="lg" mt={4}>
          We provide comprehensive support for nurses at every stage of their
          career— from preparing for licensing exams to securing top job
          placements.
        </Text>
        <Text fontSize="lg" fontWeight="bold" mt={4}>
          Join us and take the first step towards a rewarding career in the USA.
        </Text>
        <ScrollLink to="hero" smooth={true} duration={500}>
          <Button
            borderRadius={8}
            my={5}
            type="button"
            size="md"
            color={"white"}
            bgColor={"#233862"}
            variant="primary"
            fontSize="sm"
            //   minW="140px"
            //   onClick={onClick}
          >
            Join the waitlist
          </Button>
        </ScrollLink>
      </Box>
    </Stack>
  );
};

export default Features;
