// import { NavLink } from "react-router-dom";
import {
  Flex,
  VStack,
  Image,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Box,
  HStack,
  Stack,
} from "@chakra-ui/react";

import SocialLinks from "./social-links";
import { Logo } from "../atoms/icons/logo";
import FooterLinks from "../molecules/FooterLinks/FooterLinks";
import { FaPhone } from "react-icons/fa";
import { PhoneIcon } from "../atoms/icons/meeting-icon";

const footerStyles = {
  backgroundColor: "#F3F4F6",
  padding: "32px 5%",
  alignItem: "center",
  // marginHorizontal: 20,
};

const Footer = () => {
  return (
    <footer style={footerStyles}>
      <Stack
        // maxW="1024px"
        w="full"
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent={["start", "start"]}
        gap={[16, 21]}
      >
        <Flex
          maxW="1024px"
          flexDirection={["column"]}
          justifyContent="start"
          alignItems={["center", "start"]}
          w={["100%", "40%"]}
          gap={6}
        >
          <Logo />
          <VStack align="start" gap={4}>
            <Text>
              MedicStaff - The all-in-one solution for nurses, advisors, and
              recruiting agencies in the nursing career journey.
            </Text>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <VStack align="start" w={"95%"}>
                <Stack
                  justifyContent={"space-between"}
                  w={["100%"]}
                  spacing={[5]}
                  flexDir={["column", "row"]}
                >
                  <Stack flexDir={["row"]}>
                    <Stack flexDir={["row"]}>
                      <PhoneIcon />
                      <Text fontSize={[12, 16]}>Toll free</Text>
                    </Stack>
                    <Text
                      color={"#192846"}
                      fontSize={[12, 16]}
                      fontWeight={700}
                    >
                      +1 800-585-0225
                    </Text>
                  </Stack>

                  <SocialLinks />
                </Stack>
              </VStack>
            </HStack>
          </VStack>
        </Flex>
        <FooterLinks />
      </Stack>

      <VStack
        maxW="1200px"
        w="full"
        // mx="auto"
        borderBlockStart=" 1px solid #D1D5DB"
        pt={8}
        mt={16}
      >
        <Text>
          © {new Date().getFullYear()} Medic Staff, Inc. All rights reserved.
        </Text>
      </VStack>
    </footer>
  );
};

export default Footer;
