import React from "react";
import { Box, Flex, Text, SimpleGrid } from "@chakra-ui/react";
import { IMAGES } from "../../assets";
import { BigTool } from "../atoms/icons/big-tool-icon";

const HowItWorks: React.FC = () => {
  return (
    <Box
      maxW="1024px"
      justifyContent={"center"}
      mx={[5, 15]}
      bgImage={IMAGES.bigtool}
      bgRepeat="no-repeat"
      bgPosition="center"
      bgSize="contain"
      textAlign="center"
    >
      <Text fontSize="3xl" fontWeight="bold" mb={10}>
        How It Works
      </Text>
      <SimpleGrid columns={[1, 1, 3]} spacing={10}>
        <Box textAlign={"left"}>
          <Box
            bg="linear-gradient(180deg, #F9C5BD 0%, #ED4B5C 100%)"
            p={4}
            borderRadius="8"
            shadow="md"
          >
            <Text fontSize="36px" fontWeight="700" color="white">
              1
            </Text>
            <Text fontSize="20px" fontWeight="600" color="white">
              Create Your Profile
            </Text>
          </Box>
          <Text fontSize="16px" color="#374151" fontWeight={400} mt={2}>
            Sign up and fill in your details to create a comprehensive profile.
          </Text>
        </Box>
        <Box textAlign={"left"}>
          <Box
            bg="linear-gradient(180deg, #F9C5BD 0%, #ED4B5C 100%)"
            p={4}
            borderRadius="8"
            shadow="md"
          >
            <Text fontSize="36px" fontWeight="700" color="white">
              2
            </Text>
            <Text fontSize="20px" fontWeight="600" color="white">
              Upload Documents
            </Text>
          </Box>
          <Text fontSize="16px" color="#374151" fontWeight={400} mt={2}>
            Add your credentials and certifications to your profile.
          </Text>
        </Box>
        <Box textAlign={"left"}>
          <Box
            bg="linear-gradient(180deg, #F9C5BD 0%, #ED4B5C 100%)"
            p={4}
            borderRadius="8"
            shadow="md"
          >
            <Text fontSize="36px" fontWeight="700" color="white">
              3
            </Text>
            <Text fontSize="20px" fontWeight="600" color="white">
              Connect with Agencies
            </Text>
          </Box>
          <Text fontSize="16px" color="#374151" fontWeight={400} mt={2}>
            Await contact from top recruiting agencies interested in your
            skills.{" "}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default HowItWorks;
